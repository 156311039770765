import { useMemo } from "react";

import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { htmlToast } from "@/components/feedbacks/htmlToast";
import { postCart, useGetCart, useGetCartSuspense } from "@/generated/open-api/cart/cart";
import { PostCartBody } from "@/generated/open-api/schemas";
import { CartCalcApiResponse, CartProductItem, GetCartParams } from "@/generated/open-api/schemas";
import { convertApiCartToCart } from "@/models/cart/converters";
import { SnakeToCamelCaseNested } from "@/utils";
import { mapBy } from "@/utils/array";
import { convertObjToSnakeCase } from "@/utils/converters";
import { getErrorMessages } from "@/utils/error";

export type GetParsedCartParams = SnakeToCamelCaseNested<PostCartBody>;

export async function getParsedCart(params: GetParsedCartParams) {
  try {
    const res = (await postCart(convertObjToSnakeCase(params))).cart;
    if (!res) return undefined;
    return convertApiCartToCart(res);
  } catch (e) {
    htmlToast.error(getErrorMessages(e));
  }
}

/**
 * productsをObjectの配列から文字列に変換する必要がある
 */
type UseGetParsedCartParams = Omit<GetCartParams, "products"> & {
  products: CartProductItem[];
};

type UseGetParsedCartOptions = {
  query?: Partial<UseQueryOptions<CartCalcApiResponse, unknown, CartCalcApiResponse, QueryKey>>;
};

export function useGetParsedCart(
  params: UseGetParsedCartParams,
  options?: UseGetParsedCartOptions
) {
  // productsをObjectから文字列に変換
  const paramsFormatted: GetCartParams = useMemo(() => {
    return {
      ...params,
      products: JSON.stringify(params.products),
    };
  }, [params]);

  const { data, ...rest } = useGetCart<CartCalcApiResponse, unknown>(paramsFormatted, options);

  const cart = useMemo(() => {
    if (!data?.cart) return;
    return convertApiCartToCart(data.cart);
  }, [data]);

  return {
    data: {
      cart,
      discountPlanOptions: mapBy(data?.discount_plan_options ?? [], "discount_plan_name"),
    },
    ...rest,
  };
}

export function useGetParsedCartSuspense(
  params: UseGetParsedCartParams,
  options?: Parameters<typeof useGetCartSuspense>[1]
) {
  // productsをObjectから文字列に変換
  const paramsFormatted = useMemo(() => {
    return {
      ...params,
      products: JSON.stringify(params.products),
    };
  }, [params]);

  const { data: dataUnknown, ...rest } = useGetCartSuspense(paramsFormatted, options);
  const data = dataUnknown as CartCalcApiResponse;

  const cart = useMemo(() => {
    if (!data.cart) return;
    return convertApiCartToCart(data.cart);
  }, [data]);

  return { data: cart, ...rest };
}
