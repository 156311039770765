"use client";
import { useCallback, useMemo } from "react";

import { PaymentMethod } from "@/models/payment/consts";

import { LocalStagePath, NuxtStoreCart } from "./types";
import { useClientLocalStorage } from "./useClientLocalStorage";

const storeKey: LocalStagePath = `vuex.cart.form`;

/**
 * TODO: vuexのstoreを初期化し、formがundefinedにならないようにする
 * その後、useClientCartFormを使っているコンポーネントの form?.xxx の部分も修正する
 */
interface UseClientCartFormParams {
  /**
   * formの値を利用しない場合は、falseにする
   */
  isSubscription?: boolean;
}

/**
 * @param {boolean} params.isSubscription - フォームがサブスクリプション用かどうかを示す。formの値を利用しない場合は、空でもよい。
 * @returns {object} フォームの状態、フォームの状態を設定する関数、およびフォームの状態をクリアする関数を含むオブジェクト。
 */
export const useClientCartForm = (params?: UseClientCartFormParams) => {
  const { isSubscription = false } = params ?? {};
  const [rawForm, setForm] = useClientLocalStorage<NuxtStoreCart["form"] | undefined>({
    key: storeKey,
  });

  const clearForm = useCallback(() => {
    setForm(undefined);
  }, [setForm]);
  const form = useMemo(() => getValidForm(rawForm, { isSubscription }), [isSubscription, rawForm]);
  return { form, setForm, clearForm };
};

export const getValidForm = (
  form: NuxtStoreCart["form"] | undefined,
  options: { isSubscription: boolean }
) => {
  const { isSubscription } = options;
  const onlyOneTimePaymentMethods = ["ginkou", "konbini"];
  if (isSubscription && onlyOneTimePaymentMethods.includes(form?.payment_method ?? "")) {
    return { ...form, payment_method: "credit" as PaymentMethod };
  }
  return form;
};
