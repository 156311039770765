import { useCallback, useMemo } from "react";

import { QueryObserverOptions, QueryOptions, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { htmlToast } from "@/components/feedbacks/htmlToast";
import { CalcSubscriptionCartBody, Cart } from "@/generated/open-api/schemas";
import { calcSubscriptionCart } from "@/generated/open-api/subscription/subscription";
import { convertApiCartToCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";
import { SnakeToCamelCaseNested } from "@/utils";
import { HEADERS_KEYS } from "@/utils/apiUtils";
import { convertObjToSnakeCase } from "@/utils/converters";
import { getErrorMessages } from "@/utils/error";
import { objectKeys } from "@/utils/object";
import { generateEventTraceId } from "@/utils/tracingUtils";

type ParsedSubscriptionCartParams = SnakeToCamelCaseNested<CalcSubscriptionCartBody>;

type ParsedSubscriptionCart = {
  cart: CartModel | undefined;
  couponInvalid: boolean;
};

export async function getParsedSubscriptionCart(
  params: ParsedSubscriptionCartParams
): Promise<ParsedSubscriptionCart | undefined> {
  let cart: Cart | undefined;
  let couponInvalid = false;
  const eventTraceId = generateEventTraceId("subscription_cart-change-quantity");
  try {
    cart = (
      await calcSubscriptionCart(convertObjToSnakeCase(params), {
        headers: {
          [HEADERS_KEYS.EVENT_TRACE_ID]: eventTraceId,
        },
      })
    ).cart;
  } catch (err) {
    if (isAxiosError(err) && err.response?.status === 404) {
      couponInvalid = true;
      // 404が返った場合はリクエストパラメータからクーポンを除去して、もう一回POSTリクエストを投げる(※Nuxtの仕様をそのまま踏襲)
      const { coupon, ...paramsWithoutCoupon } = params;
      cart = (
        await calcSubscriptionCart(convertObjToSnakeCase(paramsWithoutCoupon), {
          headers: {
            [HEADERS_KEYS.EVENT_TRACE_ID]: eventTraceId,
          },
        })
      ).cart;
    } else {
      htmlToast.error(getErrorMessages(err));
    }
  }

  if (!cart) return { cart: undefined, couponInvalid };
  return { cart: convertApiCartToCart(cart), couponInvalid };
}

type UseParsedSubscriptionCartOptions = Omit<QueryOptions, "queryKey" | "queryFn"> &
  Omit<QueryObserverOptions, "queryKey" | "queryFn">;
/**
 * /subscription/cartはPOSTだが、選択した「商品」、「クーポン」、「ポイント」が変わったら再計算するため、GETに近い。
 * LPのcartと同じようにuseQueryを使って、GETのふうに扱う。
 */
export function useParsedSubscriptionCart(
  params: ParsedSubscriptionCartParams,
  options?: UseParsedSubscriptionCartOptions
) {
  const queryKey = useMemo(() => getParsedSubscriptionCartKey(params), [params]);
  const queryFn = useCallback(() => getParsedSubscriptionCart(params), [params]);
  const { data, ...rest } = useQuery({ queryKey, queryFn, ...options });
  const { cart: subscriptionCart, couponInvalid } = useMemo(
    () => (data as ParsedSubscriptionCart) ?? {},
    [data]
  );
  return { data: subscriptionCart as CartModel | undefined, couponInvalid, ...rest };
}

export function getParsedSubscriptionCartKey(params: ParsedSubscriptionCartParams) {
  const sortedKeys = objectKeys(params).sort();
  // ソートされたキーを使用して新しいURLSearchParamsを生成
  const sortedParams = new URLSearchParams();
  sortedKeys.forEach((key) => {
    sortedParams.append(key, JSON.stringify(params[key]));
  });
  return [`/subscription/cart`, sortedParams.toString()];
}
