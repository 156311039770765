/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * 支払いステータス:
 * `NONE` - 未支払いの状態
 * `PAID` - 支払いありの状態
 * `REFUNDED` - 支払い後、返金された状態

 */
export type PenaltyPaymentStatus = (typeof PenaltyPaymentStatus)[keyof typeof PenaltyPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PenaltyPaymentStatus = {
  NONE: "NONE",
  PAID: "PAID",
  REFUNDED: "REFUNDED",
} as const;
