import { useCallback } from "react";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntentResult } from "@stripe/stripe-js";

import { useCreate3DSecureLogs } from "@/generated/open-api/3d-secure/3d-secure";
import { Create3DSecureLogsBody } from "@/generated/open-api/schemas";
import { getSetupIntent, useCreateSetupIntent } from "@/generated/open-api/stripe/stripe";
import { NuxtStoreCartForm } from "@/storage/types/NuxtStoreCartForm";

import { ValidationError } from "../error";

export type StripePaymentLogInfo = Omit<
  Create3DSecureLogsBody,
  "path" | "verification_failed_code" | "verification_status" | "form_type"
>;

export function useStripePayment() {
  const stripe = useStripe();
  const elements = useElements();
  const { silentCreate3DSecureLogs } = useSilentCreate3DSecureLogs();
  const {
    mutateAsync: createSetupIntent,
    isError: createSetupIntentHasError,
    error: createSetupIntentError,
  } = useCreateSetupIntent();

  /**
   * コンビニ前払い、銀行振込の場合のStripeの支払いを確定する
   */
  const confirmStripePayment = useCallback(
    async (
      clientSecret: string,
      formValues: NuxtStoreCartForm
    ): Promise<PaymentIntentResult | undefined> => {
      if (stripe == null) {
        throw new Error(
          "コンビニ前払い、銀行振込払いに失敗しました。画面をリフレッシュしてもう一度お試しください。"
        );
      }

      if (formValues.payment_method === "konbini") {
        return stripe.confirmKonbiniPayment(
          clientSecret,
          {
            payment_method: {
              billing_details: { name: "**** ****", email: formValues.email! },
            },
            payment_method_options: {
              konbini: {
                confirmation_number: formValues.shipping_address!.phone,
              },
            },
          },
          {
            // false: コンビニ支払手順のダイアログを非表示
            handleActions: false,
          }
        );
      } else if (formValues.payment_method === "ginkou") {
        return stripe.confirmCustomerBalancePayment(
          clientSecret,
          { payment_method: { customer_balance: {} } },
          {
            // false: 銀行支払手順を非表示
            handleActions: false,
          }
        );
      }
    },
    [stripe]
  );

  /**
   * クレジットカード払いの場合、paymentMethodIdとintentIdを返却
   */
  const getCreditCardPaymentInfo = useCallback(
    async (logInfo: StripePaymentLogInfo) => {
      if (stripe == null) {
        throw new Error(
          "クレジットカード払いに失敗しました。画面をリフレッシュしてもう一度お試しください。"
        );
      }

      const cardElement = elements?.getElement("cardNumber");
      if (cardElement == null) {
        throw new Error(
          "カード情報が取得できませんでした。画面をリフレッシュしてもう一度お試しください。"
        );
      }

      // 支払い方法オブジェクトを作成
      // ここで設定するのは、フロントエンドで3Dセキュア認証を完了させておくため
      const createdSetupIntent = await createSetupIntent();
      if (createSetupIntentHasError) {
        throw createSetupIntentError.cause;
      }

      let verification_failed_code;
      try {
        // 3Dセキュア認証 & カードをStripeに登録する
        const cardSetupConfirmResult = await stripe.confirmCardSetup(
          createdSetupIntent.client_secret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (cardSetupConfirmResult.error) {
          verification_failed_code = cardSetupConfirmResult.error.code;
          throw new ValidationError(cardSetupConfirmResult.error.message);
        }

        // paymentMethodIdを解析
        if (
          cardSetupConfirmResult.setupIntent.payment_method == null ||
          // payment_methodがPaymentMethod objectの場合
          typeof cardSetupConfirmResult.setupIntent.payment_method !== "string"
        ) {
          throw new Error("カードの確認ができませんでした。");
        }

        const savedSetupIntent = await getSetupIntent(cardSetupConfirmResult.setupIntent.id);

        return {
          last4: savedSetupIntent.payment_method?.card?.last4,
          stripePaymentMethodId: cardSetupConfirmResult.setupIntent.payment_method,
        };
      } finally {
        // バックエンドにログを送信
        logInfo &&
          (await silentCreate3DSecureLogs({
            ...logInfo,
            verification_status: verification_failed_code ? "failure" : "success",
            verification_failed_code,
          }));
      }
    },
    [
      createSetupIntent,
      createSetupIntentError?.cause,
      createSetupIntentHasError,
      elements,
      silentCreate3DSecureLogs,
      stripe,
    ]
  );

  return { confirmStripePayment, getCreditCardPaymentInfo };
}

function useSilentCreate3DSecureLogs() {
  const { mutateAsync: create3DSecureLog } = useCreate3DSecureLogs();

  const silentCreate3DSecureLogs = useCallback(
    async (logInfo: Omit<Create3DSecureLogsBody, "path" | "form_type">) => {
      try {
        const { path, form_type } = getCurrentPathAndFormType();

        await create3DSecureLog({
          data: {
            ...logInfo,
            path,
            form_type,
          },
        });
      } catch (error) {
        // 3Dセキュアログの送信に失敗してもエラーを握りつぶす
        // eslint-disable-next-line no-console
        console.error("3Dセキュアログの送信に失敗しました: ", error);
      }
    },
    [create3DSecureLog]
  );

  return { silentCreate3DSecureLogs };
}

export function getCurrentPathAndFormType(): Pick<Create3DSecureLogsBody, "path" | "form_type"> {
  const path = location.pathname;

  let formType: Create3DSecureLogsBody["form_type"];
  if (path === "/checkout") {
    formType = "checkout";
  } else if (path === "/yearplan") {
    formType = "point_checkout";
  } else if (path.startsWith("/lp/")) {
    formType = "lp";
  } else if (path === "/mypage/address") {
    formType = "card_change";
  } else {
    throw new Error(`パス[ ${path} ]はログ送信対象外です`);
  }

  return { path, form_type: formType };
}
