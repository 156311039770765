"use client";

import { type PropsWithChildren } from "react";

import clsx from "clsx";
import Image from "next/image";

import { Column, Row } from "@/components/containers";
import { useDisableBodyScroll } from "@/utils/hooks/useDisableBodyScroll";

import closeIcon from "$/images/close.svg";

import styles from "./Modal.module.scss";

export interface ModalProps {
  className?: string;
  footerClassName?: string;
  bodyClassName?: string;
  activeClassName?: string;
  inactiveClassName?: string;
  open: boolean;
  closeModal: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  displayCloseButton?: boolean;
  backdropClose?: boolean;
}

export function Modal({
  className,
  footerClassName,
  bodyClassName,
  activeClassName,
  inactiveClassName,
  open,
  closeModal,
  header,
  footer,
  displayCloseButton = true,
  backdropClose = false,
  children,
}: PropsWithChildren<ModalProps>): React.ReactNode {
  useDisableBodyScroll(open);

  return (
    <div className={styles.root}>
      <div
        className={clsx(
          styles.modal,
          open
            ? clsx(styles.modalActive, activeClassName)
            : clsx(styles.modalInactive, inactiveClassName)
        )}
      >
        <div className={styles.modalMask} onClick={() => backdropClose && closeModal()}>
          <Column
            onClick={(e) => backdropClose && e.stopPropagation()}
            className={clsx(
              styles.modalContainer,
              displayCloseButton ? styles.maxHeightWithCloseIcon : styles.maxHeightWithoutCloseIcon,
              className
            )}
          >
            {displayCloseButton && (
              <Image
                className={styles.modalClose}
                src={closeIcon}
                alt="閉じる"
                sizes="40px"
                onClick={closeModal}
              />
            )}
            <Row className={styles.modalHeader}>{header}</Row>
            <div className={clsx(styles.modalBody, bodyClassName)}>{children}</div>
            {footer && <div className={clsx(styles.modalFooter, footerClassName)}>{footer}</div>}
          </Column>
        </div>
      </div>
    </div>
  );
}
