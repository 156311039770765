import { Customer, Order } from "@/generated/open-api/schemas";
import {
  convertApiCouponToCoupon,
  convertCouponToNuxtStoreCoupon,
} from "@/models/coupon/converters";
import {
  convertAddressToApiAddress,
  convertApiAddressToAddress,
  convertCustomerToLocalStorageCustomer,
} from "@/models/customer/converters";
import { OrderModel } from "@/models/order/type";
import {
  convertApiProductToProduct,
  convertProductToNuxtStoreProduct,
} from "@/models/product/converters";
import { NuxtCommonOrder } from "@/storage/types";

import { CustomerModel } from "../customer/type";

export const convertApiCustomerToCustomer = (apiCustomer: Customer): CustomerModel => {
  return {
    acceptsMarketing: apiCustomer.accepts_marketing!,
    address: convertApiAddressToAddress(apiCustomer.address),
    birthday: apiCustomer.birthday,
    createdAt: apiCustomer.created_at!,
    deletedAt: apiCustomer.deleted_at!,
    email: apiCustomer.email!,
    firstName: apiCustomer.first_name!,
    id: apiCustomer.id!,
    isReceiveRemind: apiCustomer.is_receive_remind!,
    lastName: apiCustomer.last_name!,
    password: apiCustomer.password,
    phone: apiCustomer.phone,
    shopifyId: apiCustomer.shopify_id,
    updatedAt: apiCustomer.updated_at!,
    verifiedEmail: apiCustomer.verified_email!,
  };
};

export const convertApiOrderToOrder = (order: Order): OrderModel => {
  const isSubscription = order.products.some((product) => product.subscription);
  return {
    id: order.id!,
    shopifyId: order.shopify_id,
    email: order.email!,
    note: order.note!,
    paymentMethod: order.payment_method!,
    deliveryDate: order.delivery_date!,
    deliveryTimezone: order.delivery_timezone!,
    totalPrice: order.total_price!,
    totalPriceCashDeliveryFee: order.total_price_cash_delivery_fee!,
    subtotalPrice: order.subtotal_price!,
    totalTax: order.total_tax!,
    currency: order.currency!,
    financialStatus: order.financial_status!,
    totalDiscounts: order.total_discounts!,
    totalLineItemsPrice: order.total_line_items_price!,
    products: order.products.map((product) => convertApiProductToProduct(product)),
    customer: order.customer ? convertApiCustomerToCustomer(order.customer) : undefined,
    createdAt: order.created_at!,
    updatedAt: order.updated_at!,
    coupon: order.coupon ? convertApiCouponToCoupon(order.coupon) : undefined,
    fulfillmentStatus: order.fulfillment_status!,
    billingAddress: convertApiAddressToAddress(order.billing_address),
    billingAddressKana: order.billing_address_kana,
    shippingAddress: convertApiAddressToAddress(order.shipping_address!)!,
    shippingAddressKana: order.shipping_address_kana,
    cancelledAt: order.cancelled_at,
    processedAt: order.processed_at,
    purpose: order.purpose,
    mediaCode: order.media_code,
    path: order.path,
    fingerprint: order.fingerprint,
    refunds: order.refunds!,
    totalRefund: order.total_refund!,
    pointDiscount: Number(order.point_discount),
    couponDiscount: Number(order.coupon_discount),
    subscriptionDiscountRate: Number(order.subscription_discount_rate),
    subscriptionId: order.subscription_id,
    isSubscription: isSubscription,
    shippingTemperature: order.shipping_temperature!,
    isNonFaceToFaceReceipt: !!order.is_non_face_to_face_receipt,
    rankUpKey: order.rank_up_key,
    paidPointsDiscount: Number(order.paid_points_discount),
    freePointsDiscount: Number(order.free_points_discount),
    isShowCashDeliveryFee: !!order.is_show_cash_delivery_fee,
    isFastDelivery: !!order.is_fast_delivery,
    deliveryLocationCode: order.delivery_location_code,
    isFreeDelivery: !!order.is_free_delivery,
    landingUrl: order.landing_url,
    shippingFee: order.shipping_fee,
    discountPlanName: order.discount_plan_name,
    discountPlanRate: order.discount_plan_rate,
  };
};

export const convertOrderToLocalStorageOrder = (order: OrderModel): NuxtCommonOrder => {
  return {
    id: order.id,
    shopify_id: order.shopifyId,
    email: order.email,
    note: order.note,
    payment_method: order.paymentMethod,
    delivery_date: order.deliveryDate,
    delivery_timezone: order.deliveryTimezone,
    total_price: order.totalPrice,
    total_price_cash_delivery_fee: order.totalPriceCashDeliveryFee,
    subtotal_price: order.subtotalPrice,
    total_tax: order.totalTax,
    currency: order.currency,
    financial_status: order.financialStatus,
    total_discounts: order.totalDiscounts,
    total_line_items_price: order.totalLineItemsPrice,
    products: order.products.map(convertProductToNuxtStoreProduct),
    customer: order.customer
      ? {
          /**
           * https://github.com/basefood/Web_PaySE/pull/3216#discussion_r1583990342
           * getCustomerのcustomerとcreatePostOrderのcustomerの肩が異なるため、歪な変換が必要
           */
          ...convertCustomerToLocalStorageCustomer(order.customer)!,
          birthday: order.customer.birthday,
          created_at: order.customer.createdAt,
          deleted_at: order.customer.deletedAt,
          updated_at: order.customer.updatedAt,
        }
      : undefined,
    created_at: order.createdAt,
    updated_at: order.updatedAt,
    coupon: order.coupon ? convertCouponToNuxtStoreCoupon(order.coupon) : undefined,
    fulfillment_status: order.fulfillmentStatus,
    billing_address: convertAddressToApiAddress(order.billingAddress),
    billing_address_kana: order.billingAddressKana,
    shipping_address: convertAddressToApiAddress(order.shippingAddress)!,
    shipping_address_kana: order.shippingAddressKana,
    cancelled_at: order.cancelledAt,
    processed_at: order.processedAt,
    purpose: order.purpose,
    media_code: order.mediaCode,
    path: order.path,
    fingerprint: order.fingerprint,
    refunds: order.refunds,
    total_refund: order.totalRefund,
    point_discount: order.pointDiscount,
    coupon_discount: order.couponDiscount,
    subscription_discount_rate: order.subscriptionDiscountRate,
    shipping_temperature: order.shippingTemperature,
    is_non_face_to_face_receipt: order.isNonFaceToFaceReceipt,
    rank_up_key: order.rankUpKey,
    paid_points_discount: order.paidPointsDiscount,
    free_points_discount: order.freePointsDiscount,
    is_show_cash_delivery_fee: order.isShowCashDeliveryFee,
    is_fast_delivery: order.isFastDelivery,
    delivery_location_code: order.deliveryLocationCode,
    is_free_delivery: order.isFreeDelivery,
    landing_url: order.landingUrl,
    shipping_fee: order.shippingFee,
  };
};
