/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  QueryClient,
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { ListValidDiscountPlans200 } from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 有効な割引プランの一覧を返す
 */
export const listValidDiscountPlans = (
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<ListValidDiscountPlans200>(
    { url: `/valid_discount_plans`, method: "GET", signal },
    options
  );
};

export const getListValidDiscountPlansQueryKey = () => {
  return [`/valid_discount_plans`] as const;
};

export const getListValidDiscountPlansQueryOptions = <
  TData = Awaited<ReturnType<typeof listValidDiscountPlans>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listValidDiscountPlans>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListValidDiscountPlansQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listValidDiscountPlans>>> = ({ signal }) =>
    listValidDiscountPlans(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listValidDiscountPlans>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListValidDiscountPlansQueryResult = NonNullable<
  Awaited<ReturnType<typeof listValidDiscountPlans>>
>;
export type ListValidDiscountPlansQueryError = ErrorType<unknown>;

/**
 * @summary 有効な割引プランの一覧を返す
 */
export const useListValidDiscountPlans = <
  TData = Awaited<ReturnType<typeof listValidDiscountPlans>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof listValidDiscountPlans>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListValidDiscountPlansQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 有効な割引プランの一覧を返す
 */
export const prefetchListValidDiscountPlans = async <
  TData = Awaited<ReturnType<typeof listValidDiscountPlans>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof listValidDiscountPlans>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getListValidDiscountPlansQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getListValidDiscountPlansSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof listValidDiscountPlans>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof listValidDiscountPlans>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListValidDiscountPlansQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listValidDiscountPlans>>> = ({ signal }) =>
    listValidDiscountPlans(requestOptions, signal);

  return { queryKey, queryFn, staleTime: 30000, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof listValidDiscountPlans>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListValidDiscountPlansSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof listValidDiscountPlans>>
>;
export type ListValidDiscountPlansSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary 有効な割引プランの一覧を返す
 */
export const useListValidDiscountPlansSuspense = <
  TData = Awaited<ReturnType<typeof listValidDiscountPlans>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<Awaited<ReturnType<typeof listValidDiscountPlans>>, TError, TData>
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListValidDiscountPlansSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary 有効な割引プランの一覧を返す
 */
export const prefetchListValidDiscountPlansSuspense = async <
  TData = Awaited<ReturnType<typeof listValidDiscountPlans>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof listValidDiscountPlans>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getListValidDiscountPlansSuspenseQueryOptions(options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};
