import { useMemo } from "react";

import { useListValidDiscountPlans } from "@/generated/open-api/discount-plan/discount-plan";
import { mapBy } from "@/utils/array";

/**
 * 元のuseListValidDiscountPlansが配列で割引プランを返すが、DiscountPlanName-DiscountPlanの形式に変換して返す
 * @returns
 */
export const useDiscountPlans = () => {
  const { data: discountPlansData, ...rest } = useListValidDiscountPlans();
  const data = useMemo(() => {
    const discountPlans = (discountPlansData?.discount_plans ?? []).map((plan) => ({
      ...plan,
    }));
    return mapBy(discountPlans, "discount_plan_name");
  }, [discountPlansData?.discount_plans]);
  return { data, ...rest };
};
