import { useMemo } from "react";

import {
  getCustomerOrderAndDeliveryStatus,
  useGetCustomerOrderAndDeliveryStatus,
} from "@/generated/open-api/customer/customer";
import { convertApiOrderWithDeliveryDateToOrderWithDeliveryDate } from "@/models/orderWithDeliveryDate/converters";

export const getParsedOrderWithDeliveryDate = async (orderId: string | undefined) => {
  if (!orderId) return;

  const { order_with_delivery_date } = await getCustomerOrderAndDeliveryStatus({
    order_id: orderId,
  });
  if (!order_with_delivery_date) return;

  return convertApiOrderWithDeliveryDateToOrderWithDeliveryDate(order_with_delivery_date);
};

export const useParsedGetOrderWithDeliveryDate = (orderId: string | undefined) => {
  const { data, ...rest } = useGetCustomerOrderAndDeliveryStatus(
    { order_id: orderId! },
    { query: { enabled: !!orderId } }
  );

  const orderWithDeliveryDate = useMemo(() => {
    if (!data) return;
    return convertApiOrderWithDeliveryDateToOrderWithDeliveryDate(data.order_with_delivery_date);
  }, [data]);

  return { data: orderWithDeliveryDate, ...rest };
};
