import clsx from "clsx";
import NextImage, { ImageProps as NextImageProps } from "next/image";

import styles from "./Image.module.scss";

export interface ImageProps extends NextImageProps {}

export function Image({ className, alt, src, ...restProps }: ImageProps): React.ReactNode {
  return (
    <NextImage
      alt={alt}
      src={src}
      className={clsx(styles.image, className)}
      sizes="100vw"
      {...restProps}
    />
  );
}
