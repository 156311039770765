"use client";
import { useCallback, useState } from "react";

import clsx from "clsx";

import { Column, Row } from "@/components/containers";

import styles from "./Accordion.module.scss";

export interface AccordionProps {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  openIcon?: React.ReactNode;
  closeIcon?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChanged?: (open: boolean) => void;
  "data-testid"?: string;
}

const defaultOpenIcon = (
  <img
    className={styles.iconOpen}
    src={"https://asset.basefood.co.jp/images/navi/icon-plus.png"}
    alt={"close"}
  />
);

const defaultCloseIcon = (
  <img
    className={styles.iconClose}
    src={"https://asset.basefood.co.jp/images/navi/icon-minus.png"}
    alt={"open"}
  />
);

export function Accordion({
  title,
  children,
  className,
  openIcon = defaultOpenIcon,
  closeIcon = defaultCloseIcon,
  open: propsOpen,
  defaultOpen,
  onOpenChanged,
  "data-testid": testId,
}: AccordionProps) {
  const [innerOpen, setInnerOpen] = useState(defaultOpen ?? propsOpen ?? false);
  const open = propsOpen ?? innerOpen;

  const handleToggle = useCallback(() => {
    setInnerOpen((prev) => {
      onOpenChanged?.(!prev);
      return !prev;
    });
  }, [onOpenChanged]);

  return (
    <Column className={clsx(className)}>
      <Row
        className={clsx(styles.titleContainer, "text__m text__bold")}
        onClick={handleToggle}
        data-testid={testId}
      >
        <Row>{title}</Row>
        {open ? closeIcon : openIcon}
      </Row>
      <Column className={clsx("text__m", styles.body, open ? styles.bodyOpen : styles.bodyClose)}>
        {children}
      </Column>
    </Column>
  );
}
