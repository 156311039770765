/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation } from "@tanstack/react-query";
import type { MutationFunction, UseMutationOptions } from "@tanstack/react-query";
import type {
  Create3DSecureLogs200,
  Create3DSecureLogs400,
  Create3DSecureLogsBody,
} from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * 3Dセキュア認証の実行記録を保存する
 * @summary 3d secure logs create
 */
export const create3DSecureLogs = (
  create3DSecureLogsBody: Create3DSecureLogsBody,
  options?: SecondParameter<typeof customAxiosInstance>
) => {
  const formUrlEncoded = customFormUrlEncodedFn(create3DSecureLogsBody);
  return customAxiosInstance<Create3DSecureLogs200>(
    {
      url: `/3d-secure/logs`,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: formUrlEncoded,
    },
    options
  );
};

export const getCreate3DSecureLogsMutationOptions = <
  TError = ErrorType<Create3DSecureLogs400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof create3DSecureLogs>>,
    TError,
    { data: Create3DSecureLogsBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof create3DSecureLogs>>,
  TError,
  { data: Create3DSecureLogsBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof create3DSecureLogs>>,
    { data: Create3DSecureLogsBody }
  > = (props) => {
    const { data } = props ?? {};

    return create3DSecureLogs(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type Create3DSecureLogsMutationResult = NonNullable<
  Awaited<ReturnType<typeof create3DSecureLogs>>
>;
export type Create3DSecureLogsMutationBody = Create3DSecureLogsBody;
export type Create3DSecureLogsMutationError = ErrorType<Create3DSecureLogs400>;

/**
 * @summary 3d secure logs create
 */
export const useCreate3DSecureLogs = <
  TError = ErrorType<Create3DSecureLogs400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof create3DSecureLogs>>,
    TError,
    { data: Create3DSecureLogsBody },
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getCreate3DSecureLogsMutationOptions(options);

  return useMutation(mutationOptions);
};
