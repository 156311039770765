"use client";
import { useCallback, useMemo, useState } from "react";

import { Column } from "@/components/containers";
import { removeFalsy } from "@/utils/array";
import { isPrimitive } from "@/utils/object";

import { PanelSelectorItem } from "./PanelSelectorItem";

export interface PanelSelectorOption<T> {
  id?: string;
  title: React.ReactNode;
  content?: React.ReactNode;
  value: T;
  disabled?: boolean;
  "data-testid"?: string;
}

export interface PanelSelectorProps<T> {
  options: ReadonlyArray<PanelSelectorOption<T> | false | undefined>;
  onChange?: (value: T) => void;
  value?: T;
  defaultValue?: T;
  className?: string;
  disabledReason?: string;
  labelTwoRows?: boolean;
}

export function PanelSelector<T>({
  options,
  onChange,
  value: propsValue,
  defaultValue,
  className,
  disabledReason,
  labelTwoRows,
}: PanelSelectorProps<T>): React.ReactNode {
  const [innerValue, setInnerValue] = useState(defaultValue);

  const handleChange = useCallback(
    (newValue: T) => {
      onChange?.(newValue);
      setInnerValue(newValue);
    },
    [onChange]
  );

  const optionsWithValue = useMemo(
    () =>
      removeFalsy(options).map((option) => {
        const { value } = option;
        const selectorValue = isPrimitive(value) ? String(value) : JSON.stringify(value);
        return { ...option, selectorValue };
      }),
    [options]
  );

  const value = propsValue ?? innerValue;

  return (
    <Column className={className}>
      {optionsWithValue.map((option) => {
        const isSelected = option.value === value;
        return (
          <PanelSelectorItem
            key={option.selectorValue}
            title={option.title}
            isSelected={isSelected}
            onSelect={() => handleChange(option.value)}
            content={option.content}
            disabled={option.disabled}
            labelTwoRows={labelTwoRows}
            data-testid={option["data-testid"]}
          />
        );
      })}
      {disabledReason && <p className="text__s text__gray__dark">{disabledReason}</p>}
    </Column>
  );
}
