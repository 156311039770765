/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns";

import { TimeStamp } from "@/generated/open-api/schemas";
import { CamelToSnakeCaseNested, SnakeToCamelCaseNested } from "@/utils";

const convertObjKeysCase = (
  caseConverter: (str: string) => string,
  obj?: Record<string, any> | Record<string, any>[]
): Record<string, any> | undefined => {
  if (!obj) {
    return;
  }

  if (Array.isArray(obj)) {
    return obj.map((child) => convertObjKeysCase(caseConverter, child));
  }

  return Object.entries(obj).reduce(
    (acc, [befCaseKey, value]) => {
      const aftCaseKey = caseConverter(befCaseKey);

      // typeof [] === "object" なので、配列を先に処理する
      if (Array.isArray(value)) {
        acc[aftCaseKey] = value.map((child) => {
          // typeof null === "object" なので、nullを除外する
          if (typeof child === "object" && child !== null) {
            return convertObjKeysCase(caseConverter, child as Record<string, unknown>);
          }
          return child;
        });
      } else if (typeof value === "object" && value !== null) {
        acc[aftCaseKey] = convertObjKeysCase(caseConverter, value as Record<string, unknown>);
      } else {
        acc[aftCaseKey] = value;
      }

      return acc;
    },
    {} as Record<string, any>
  );
};

export const camelCaseToSnakeCase = (str: string): string => {
  return str.replace(/[A-Z]/g, (s) => "_" + s.charAt(0).toLowerCase());
};

export const snakeCaseToCamelCase = (str: string): string => {
  return str.replace(/_./g, (s) => s.charAt(1).toUpperCase());
};

/**
 * 全てのキーをcamelCaseからsnakeCaseへ変換する
 */
export const convertObjToSnakeCase = <T extends object>(obj?: T) =>
  convertObjKeysCase(camelCaseToSnakeCase, obj) as CamelToSnakeCaseNested<T>;

/**
 * 全てのキーをsnakeCaseからcamelCaseへ変換する
 */
export const convertObjToCamelCase = <T extends object>(obj?: T) =>
  convertObjKeysCase(snakeCaseToCamelCase, obj) as SnakeToCamelCaseNested<T>;

export const convertTimeStampToDate = (
  timeStamp: TimeStamp | SnakeToCamelCaseNested<TimeStamp>
): Date => {
  const { year, month, day, hour, minute, second } = timeStamp;
  // timeStampのmonthは1月が1、12月が12だけど、Dateのmonthは0が1月、11が12月になる
  return new Date(year, month - 1, day, hour, minute, second);
};

export const convertTimeStampToString = (
  timeStamp?: TimeStamp | SnakeToCamelCaseNested<TimeStamp>
): string | undefined => {
  if (!timeStamp) {
    return;
  }

  const date = convertTimeStampToDate(timeStamp);
  if (date.toString() === "Invalid Date") {
    return;
  }

  // "2024/04/30 09:31:24"のフォーマットで返す
  return format(date, "yyyy/MM/dd HH:mm:ss");
};
