/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * プラン割引で適用される割引の種類を表現するキー。継続割引がない場合としてDEFAULTの選択肢があるが、DEFAULTはcustomer_discount_plansでは空になる。
 */
export type DiscountPlanName = (typeof DiscountPlanName)[keyof typeof DiscountPlanName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DiscountPlanName = {
  "3MONTH": "3MONTH",
  DEFAULT: "DEFAULT",
} as const;
