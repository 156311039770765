import { useMemo } from "react";

import { useGetCustomerOrderDetail } from "@/generated/open-api/customer/customer";
import { convertApiOrderToOrder } from "@/models/order/converters";

export const useParsedGetCustomerOrderDetail = (orderId: string) => {
  const { data, ...rest } = useGetCustomerOrderDetail({ order_id: orderId });
  const parsed = useMemo(() => {
    if (!data?.order) return;
    return convertApiOrderToOrder(data.order);
  }, [data?.order]);

  return { data: parsed, ...rest };
};
