/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * 1:通常商品 2:アウトレット商品
 */
export type ProductRequestSalesType =
  (typeof ProductRequestSalesType)[keyof typeof ProductRequestSalesType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductRequestSalesType = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;
