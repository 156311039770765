import { differenceInDays, startOfDay, parse } from "date-fns";

import { Subscription, SubscriptionResponse } from "@/generated/open-api/schemas";
import { convertApiNutrientToNutrient } from "@/models/nutrient/converters";
import {
  convertApiProductToProduct,
  convertProductToApiProduct,
} from "@/models/product/converters";
import { convertObjToCamelCase, convertObjToSnakeCase } from "@/utils/converters";

import { SkipInfo, SubscriptionResponseModel, SubscriptionModel } from "./type";
import { parseIntOrZero } from "../common";
import { convertApiAddressToAddress } from "../customer/converters";

export const convertApiSubscriptionToSubscription = (
  apiSubscription: SubscriptionResponse
): SubscriptionResponseModel => {
  return {
    subscription: apiSubscription.subscription
      ? convertApiInnerSubscriptionToInnerSubscription(apiSubscription.subscription)
      : undefined,
    freezeSubscription: apiSubscription.freezeSubscription
      ? convertApiInnerSubscriptionToInnerSubscription(apiSubscription.freezeSubscription)
      : undefined,
    comebackFreezeSubscriptionProducts:
      apiSubscription.comebackFreezeSubscriptionProducts?.map(convertApiProductToProduct) || [],
    comebackSubscriptionProducts:
      apiSubscription.comebackSubscriptionProducts?.map(convertApiProductToProduct) || [],
    hasAcceptAgreement: !!apiSubscription.hasAcceptAgreement,
    freezeDeliveryArea: !!apiSubscription.freezeDeliveryArea,
    nutrients: apiSubscription.nextNutrients?.map(convertApiNutrientToNutrient) || [],
    freezeNutrients: apiSubscription.nextFreezeNutrients?.map(convertApiNutrientToNutrient) || [],
    countRestOfInviteLimit: apiSubscription.countRestOfInviteLimit,
    customerDiscountPlan: apiSubscription.customerDiscountPlan
      ? {
          ...convertObjToCamelCase(apiSubscription.customerDiscountPlan),
          totalDiscountedAmount: parseIntOrZero(
            apiSubscription.customerDiscountPlan.total_discounted_amount
          ),
          planDiscountedAmount: parseIntOrZero(
            apiSubscription.customerDiscountPlan.plan_discounted_amount
          ),
          needToGoalDays: differenceInDays(
            parse(apiSubscription.customerDiscountPlan.end_date, "yyyy/MM/dd", new Date()),
            // 日付の差分を繰り上げるため、startOfDayを使って日付を調整
            startOfDay(new Date())
          ),
        }
      : undefined,
  };
};

const convertApiInnerSubscriptionToInnerSubscription = (
  subscription: Subscription
): SubscriptionModel => {
  return {
    id: subscription.id!,
    customerId: subscription.customer_id!,
    nextOrderArrivalDate: subscription.next_order_arrival_date!,
    nextOrderArrivalTimezone: subscription.next_order_arrival_timezone!,
    products: subscription.products?.map(convertApiProductToProduct) || [],
    skip: subscription.skip!,
    paymentMethod: subscription.payment_method! as "credit" | "amazon" | "daibiki",
    coupon: subscription.coupon!,
    reason: subscription.reason,
    stripeCustomerId: subscription.stripe_customer_id!,
    orderIds: subscription.order_ids!,
    differentBillingAddress:
      convertApiAddressToAddress(subscription.different_billing_address) ?? null,
    amazonBillingAgreementId: subscription.amazon_billing_agreement_id,
    lastOrderDate: subscription.last_order_date!,
    lastOrderTimezone: subscription.last_order_timezone!,
    step: subscription.step!,
    overrideNextOrderArrivalTimezone: subscription.override_next_order_arrival_timezone,
    overrideNextOrderArrivalDate: subscription.override_next_order_arrival_date,
    deletedAt: subscription.deleted_at,
    deliveryList: subscription.delivery_list!,
    isPastDiscount: subscription.is_past_discount!,
    calcNextOrderArrivalDate: subscription.calc_next_order_arrival_date!,
    calcNextOrderArrivalTimezone: subscription.calc_next_order_arrival_timezone!,
    nonFaceToFaceReceipt: subscription.non_face_to_face_receipt!,
    fingerprint: subscription.fingerprint,
    cardExpire: subscription.card_expire,
    cardExpiredAt: subscription.card_expire
      ? parseDateFromYYYYMM(subscription.card_expire)
      : undefined,
    chargePermissionId: subscription.charge_permission_id,
    nextUsePoint: subscription.next_use_point!,
    nextUsePointType: subscription.next_use_point_type!,
    isFastDelivery: subscription.is_fast_delivery!,
    deliveryLocationCode: subscription.delivery_location_code,
    type: subscription.type!,
    createdAt: subscription.created_at!,
    orders: subscription.orders?.map(convertObjToCamelCase) || [],
    calcNextOrderArrivalTimezoneStr: subscription.calc_next_order_arrival_timezone_str!,
    skipInfo: subscription.skipInfo as SkipInfo,
    nextOrderFixDate: subscription.next_order_fix_date!,
    nextOrderChangeDeadlineDate: subscription.next_order_change_deadline_date!,
    cardExpireNextMonthAlert: subscription.card_expire_next_month_alert!,
    cardExpireAlert: subscription.card_expire_alert!,
    deliveryPaymentFailedAlert: subscription.delivery_payment_failed_alert!,
    suspensionAlert: subscription.suspension_alert!,
    isFirstSubscription: subscription.is_first_subscription!,
    isOrderFixDateChangedByInventry: subscription.is_order_fix_date_changed_by_inventry!,
  };
};

export function parseDateFromYYYYMM(yyyyMM: number) {
  const yyyyMMStr = yyyyMM.toString();
  if (!/^\d{6}$/.test(yyyyMMStr)) {
    throw new Error("入力が不正な形式です。yyyyMM形式の文字列を入力してください。");
  }

  // date-fnsのparse関数を使ってDateオブジェクトを作成
  const date = parse(yyyyMMStr, "yyyyMM", new Date());
  return date;
}

export function convertSubscriptionToSubscriptionApi(
  subscription: SubscriptionModel
): Subscription {
  // TODO: フロントエンドに定義されているAddress.addressLine1はundefined許容するため、型が合わない。undefinedを許容するか要チェック
  return {
    ...convertObjToSnakeCase(subscription),
    products: subscription.products.map((product) => convertProductToApiProduct(product)),
  } as Subscription;
}
