"use client";
import React, { useState } from "react";

import clsx from "clsx";
import { ImageProps } from "next/image";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import styles from "./ThumbSlider.module.scss";
import { Image } from "../Image";

export interface ThumbSliderProps extends React.ComponentProps<"div"> {
  images: ImageProps[];
}
export function ThumbSlider({ images, ...divProps }: ThumbSliderProps) {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  return (
    <div {...divProps}>
      <Swiper
        spaceBetween={10}
        navigation={{
          enabled: true,
          prevEl: `.${styles.buttonPrev}`,
          nextEl: `.${styles.buttonNext}`,
        }}
        thumbs={{ swiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles.mainSwiper}
      >
        <button className={clsx(styles.button, styles.buttonPrev)} aria-label="前へ">
          <svg viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="16" fill="#000" />
            <path
              d="M18 11 L12 16 L18 21"
              stroke="#fff"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button className={clsx(styles.button, styles.buttonNext)} aria-label="次へ">
          <svg viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="16" fill="#000" />
            <path
              d="M13 11 L19 16 L13 21"
              stroke="#fff"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <Image {...image} alt={image.alt} width={334} height={334} priority={index === 0} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        onSwiper={setSwiper}
        spaceBetween={12}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className={styles.thumbSwiper}
        // containerのpaddingを打ち消し、スライドが左端に寄るようにする
        style={{ marginLeft: -28, marginRight: -28, paddingLeft: 28, paddingRight: 28 }}
      >
        {images.map((image, index) => {
          return (
            // SSRでlayout shiftを防ぐために、styleを強制指定する。ローディングが終わると、swiperのstyleが適用される
            <SwiperSlide key={index} style={{ width: 71, marginRight: 12 }}>
              <Image {...image} alt={image.alt} width={71} height={71} sizes="71px" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
