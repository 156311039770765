import clsx from "clsx";

import styles from "./Column.module.scss";

type JustifyContent =
  | "center"
  | "start"
  | "end"
  | "flex-start"
  | "flex-end"
  | "left"
  | "right"
  | "space-between"
  | "space-around"
  | "space-evenly"
  | "stretch";

export type ColumnProps = React.ComponentProps<"div"> & {
  align?: "start" | "center" | "end";
  justify?: JustifyContent;
  gap?: number;
  flexFull?: boolean;
  flex?: "wrap" | "nowrap" | "wrap-reverse";
  fullWidth?: boolean;
};

export function Column(props: ColumnProps): React.ReactNode {
  const { className, align, justify, gap, style, flexFull, flex, fullWidth, ...rest } = props;
  return (
    <div
      className={clsx(
        styles.root,
        align && styles[`align-${align}`],
        flexFull && styles["flex-1"],
        flex && styles[`flex-${flex}`],
        fullWidth && styles.fullWidth,
        className
      )}
      style={{ ...style, gap, justifyContent: justify }}
      {...rest}
    />
  );
}
