import { TimeStamp } from "@/generated/open-api/schemas";

export const convertDateStrToTimeStamp = <T extends string | undefined>(
  dateStr: T
): T extends undefined ? TimeStamp | undefined : TimeStamp => {
  if (!dateStr) return undefined as T extends undefined ? TimeStamp | undefined : TimeStamp;
  const date = new Date(dateStr);
  return {
    day: date.getDate(),
    dayOfWeek: date.getDay(),
    dayOfYear: 0,
    formatted: dateStr,
    hour: date.getHours(),
    micro: 0,
    minute: date.getMinutes(),
    // monthは0から始まるため+1する
    month: date.getMonth() + 1,
    second: date.getSeconds(),
    timestamp: date.getTime(),
    timezone: { timezone: "Asia/Tokyo", timezone_type: 3 },
    year: date.getFullYear(),
  };
};

export const parseIntOrZero = (value: string | undefined | number): number => {
  if (value === undefined) return 0;
  if (typeof value === "number") return value;
  const res = parseInt(value);
  if (isNaN(res)) return 0;
  return res;
};
