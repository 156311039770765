import { useCallback } from "react";

import { QueryClient, useQueryClient } from "@tanstack/react-query";

import {
  prefetchGetCustomer,
  prefetchGetCustomerOrderDetail,
  prefetchGetCustomerOrders,
  prefetchGetCustomerPenaltyPayments,
  prefetchGetCustomerPointDetail,
  prefetchGetCustomerPoints,
} from "@/generated/open-api/customer/customer";

function usePrefetchWithParams<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (queryClient: QueryClient, params: any, options?: any) => unknown,
>(prefetch: T) {
  const queryClient = useQueryClient();
  return useCallback(
    (params: Parameters<T>[1], options?: Parameters<T>[2]) => () => {
      prefetch(queryClient, params, options);
    },
    [prefetch, queryClient]
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function usePrefetchWithoutParams<T extends (queryClient: QueryClient, options?: any) => unknown>(
  prefetch: T
) {
  const queryClient = useQueryClient();
  return useCallback(
    (options?: Parameters<T>[1]) => {
      prefetch(queryClient, options);
    },
    [prefetch, queryClient]
  );
}

export function usePrefetchCallbacks() {
  return {
    prefetchCustomerCallback: usePrefetchWithoutParams(prefetchGetCustomer),
    prefetchOrdersCallback: usePrefetchWithParams(prefetchGetCustomerOrders),
    prefetchPointsCallback: usePrefetchWithParams(prefetchGetCustomerPoints),
    prefetchOrderDetailCallback: usePrefetchWithParams(prefetchGetCustomerOrderDetail),
    prefetchPointDetailCallback: usePrefetchWithParams(prefetchGetCustomerPointDetail),
    prefetchPenaltyPaymentsCallback: usePrefetchWithParams(prefetchGetCustomerPenaltyPayments),
  };
}
