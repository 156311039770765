"use client";
import { forwardRef, useCallback, useState } from "react";

import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
  MobileDatePicker as MuiMobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import clsx from "clsx";
import { format, isValid } from "date-fns";
import { ja } from "date-fns/locale";

import { dateFormats } from "@/style/muiTheme";
import { Merge } from "@/utils";

import styles from "./DatePicker.module.scss";

function getWeekdayShort(date: Date): string {
  // 'E'は曜日の省略形（日本語の場合は1文字）を返します
  return format(date, "E", { locale: ja });
}

type Value<T extends boolean> = T extends true ? Date | null : Date;

type OwnProps<T extends boolean = false> = {
  value?: Value<T>;
  defaultValue?: Value<T>;
  nullable?: T;
  onChange?: (date: Value<T>) => void;
  placeholder?: string;
};

export type DatePickerProps<T extends boolean> = Merge<MuiDatePickerProps<Date>, OwnProps<T>>;

export const DatePicker = forwardRef(function DatePicker<T extends boolean>(
  {
    value: propsValue,
    nullable,
    defaultValue,
    onChange,
    placeholder = "2024/04/01",
    className,
    disabled,
    ...rest
  }: DatePickerProps<T>,
  ref: React.Ref<HTMLDivElement>
) {
  const [innerValue, setInnerValue] = useState<Date | null>(defaultValue ?? null);
  const value = propsValue ?? innerValue;

  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (date: Date | null) => {
      setOpen(false);
      if (nullable || (date !== null && isValid(date))) {
        onChange?.(date as Value<T>);
        setInnerValue(date);
      }
    },
    [nullable, onChange]
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} dateFormats={dateFormats} adapterLocale={ja}>
      {/* スマホ用 */}
      <MuiMobileDatePicker
        disabled={disabled}
        className={clsx(styles.root, disabled && styles.rootDisabled, className, "clear__pc")}
        value={value}
        onChange={handleChange}
        slotProps={{
          calendarHeader: { format: "yyyy年 M月" },
          textField: { placeholder, "aria-disabled": true },
          actionBar: { className: styles.mobileActionBar },
        }}
        dayOfWeekFormatter={getWeekdayShort}
        {...rest}
        ref={ref}
      />
      {/* PC用、PickerがOpenするタイミングをInputクリック時にするため、個別に修正 */}
      <MuiDatePicker
        open={open}
        disabled={disabled}
        onClose={() => setOpen(false)}
        className={clsx(styles.root, disabled && styles.rootDisabled, className, "clear__sp")}
        value={value}
        onChange={handleChange}
        slotProps={{
          calendarHeader: { format: "yyyy年 M月" },
          inputAdornment: { position: "start", onClick: () => setOpen(true) },
          textField: {
            placeholder,
            "aria-disabled": true,
            "aria-readonly": true,
            // @ts-expect-error readOnlyがないと矢印キーで日付を変更できてしまう
            readOnly: true,
            // キーボードの入力不可にする
            inputProps: { readOnly: true },
            InputProps: { readOnly: true },
            onClick: () => setOpen(true),
          },
        }}
        dayOfWeekFormatter={getWeekdayShort}
        {...rest}
        ref={ref}
      />
    </LocalizationProvider>
  );
});
