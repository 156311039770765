import { DiscountPlanOption } from "@/generated/open-api/schemas";

import { parseIntOrZero } from "../common";
import { DiscountPlanOptionModel } from "./type";

export const convertApiDiscountPlanOptionToDiscountPlanOption = (
  data: DiscountPlanOption
): DiscountPlanOptionModel => {
  return {
    ...data,
    discount_amount: parseIntOrZero(data.discount_amount),
    discount_amount_with_invite_coupon: parseIntOrZero(data.discount_amount_with_invite_coupon),
    first_subscription_total_price: parseIntOrZero(data.first_subscription_total_price),
    normal_subscription_total_price: parseIntOrZero(data.normal_subscription_total_price),
  };
};
