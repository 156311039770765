/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * 認証結果
 */
export type Create3DSecureLogsBodyVerificationStatus =
  (typeof Create3DSecureLogsBodyVerificationStatus)[keyof typeof Create3DSecureLogsBodyVerificationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Create3DSecureLogsBodyVerificationStatus = {
  success: "success",
  reauth_required: "reauth_required",
  failure: "failure",
} as const;
