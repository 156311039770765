/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */

/**
 * フォーム種別
 */
export type Create3DSecureLogsBodyFormType =
  (typeof Create3DSecureLogsBodyFormType)[keyof typeof Create3DSecureLogsBodyFormType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Create3DSecureLogsBodyFormType = {
  checkout: "checkout",
  point_checkout: "point_checkout",
  lp: "lp",
  chatbot: "chatbot",
  card_change: "card_change",
} as const;
