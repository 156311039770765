import { useCallback } from "react";

import { useSaveCheckoutProgress as useSaveCheckoutProgressOriginal } from "@/generated/open-api/cart/cart";
import { validateFormInputs } from "@/utils";

/**
 * フロントエンド側でvalidateを行うため、useSaveCheckoutProgressをカスタマイズする
 */
export function useSaveCheckoutProgress() {
  const { mutateAsync, ...rest } = useSaveCheckoutProgressOriginal();

  const saveCheckoutProgress = useCallback<typeof mutateAsync>(
    ({ data }) => {
      // フロントエンド側でvalidateを行う
      validateFormInputs(data);

      return mutateAsync({ data });
    },
    [mutateAsync]
  );

  return { mutateAsync: saveCheckoutProgress, ...rest };
}
