import { useCallback, useMemo } from "react";

import { useSuspenseQuery, UseSuspenseQueryOptions } from "@tanstack/react-query";

import { getGetPointsQueryKey, getPoints } from "@/generated/open-api/customer/customer";
import { convertApiPointResponseToPoints } from "@/models/point/converters";

type UseParsedGetPointsOptions<Enabled extends boolean> = Omit<
  UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPoints>> | null>,
  "queryKey" | "queryFn"
> & {
  enabled?: Enabled;
};

type UseParsedGetPointsResult<Enabled extends boolean> = {
  data: Enabled extends true
    ? ReturnType<typeof convertApiPointResponseToPoints>
    : ReturnType<typeof convertApiPointResponseToPoints> | null;
} & Omit<ReturnType<typeof useSuspenseQuery>, "data">;

/**
 * ログインしていない状態でも、hooksの発火で401エラーにならないようにするために
 * enabledを追加しています。
 */
export const useParsedGetPoints = <Enabled extends boolean = true>(
  options?: UseParsedGetPointsOptions<Enabled>
): UseParsedGetPointsResult<Enabled> => {
  const { enabled = true, ...restOptions } = options ?? {};
  const queryKey = useMemo(
    () => (enabled ? getGetPointsQueryKey() : [...getGetPointsQueryKey(), enabled]),
    [enabled]
  );
  const queryFn = useCallback(() => (enabled ? getPoints() : null), [enabled]);
  const { data, ...restResult } = useSuspenseQuery({ queryKey, queryFn, ...restOptions });
  const points = useMemo(() => (data ? convertApiPointResponseToPoints(data) : null), [data]);
  return { data: points, ...restResult } as UseParsedGetPointsResult<Enabled>;
};
