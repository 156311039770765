import { useCallback, useMemo } from "react";

import {
  QueryObserverOptions,
  QueryOptions,
  useQuery,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { isAxiosError } from "axios";

import { htmlToast } from "@/components/feedbacks/htmlToast";
import { postCart } from "@/generated/open-api/cart/cart";
import { DiscountPlanName, PostCartBody } from "@/generated/open-api/schemas";
import { convertApiCartToCart } from "@/models/cart/converters";
import { CartModel } from "@/models/cart/type";
import { convertApiDiscountPlanOptionToDiscountPlanOption } from "@/models/discountPlan/converters";
import { DiscountPlanOptionModel } from "@/models/discountPlan/type";
import { SnakeToCamelCaseNested } from "@/utils";
import { mapBy } from "@/utils/array";
import { convertObjToSnakeCase } from "@/utils/converters";
import { getErrorMessages } from "@/utils/error";
import { objectKeys } from "@/utils/object";

export type UsePostParsedCartParams = SnakeToCamelCaseNested<PostCartBody>;

export type UsePostParsedCartOptions = Omit<
  QueryOptions<PostParsedCartResponse>,
  "queryKey" | "queryFn"
> &
  Omit<QueryObserverOptions<PostParsedCartResponse>, "queryKey" | "queryFn">;

/**
 * HTTPメソッドがPOSTでSuspenseのhooksが生成されないため自作する
 */
export function usePostParsedCart(
  params: UsePostParsedCartParams,
  options?: UsePostParsedCartOptions
) {
  const queryKey = useMemo(() => getParsedCartKey(params), [params]);
  const queryFn = useCallback(() => postParsedCart(params), [params]);
  return useQuery<PostParsedCartResponse>({ queryFn, queryKey, ...options });
}

export function usePostParsedCartSuspense(
  params: UsePostParsedCartParams,
  options?: UsePostParsedCartOptions
) {
  const queryKey = useMemo(() => getParsedCartKey(params), [params]);
  const queryFn = useCallback(() => postParsedCart(params), [params]);
  return useSuspenseQuery({ queryKey, queryFn, ...options });
}

function getParsedCartKey(params: UsePostParsedCartParams) {
  const sortedKeys = objectKeys(params).sort();
  // ソートされたキーを使用して新しいURLSearchParamsを生成
  const sortedParams = new URLSearchParams();
  sortedKeys.forEach((key) => {
    sortedParams.append(key, JSON.stringify(params[key]));
  });
  return [`/cart`, sortedParams.toString()];
}

type PostParsedCartResponse = {
  cart: CartModel | undefined;
  discountPlanOptions: Record<DiscountPlanName, DiscountPlanOptionModel | undefined> | undefined;
};

async function postParsedCart(params: UsePostParsedCartParams): Promise<PostParsedCartResponse> {
  const totalRes: PostParsedCartResponse = {
    cart: undefined,
    discountPlanOptions: undefined,
  };
  try {
    const res = await postCart(convertObjToSnakeCase(params));
    const discountPlanOptions = mapBy(
      res.discount_plan_options?.map(convertApiDiscountPlanOptionToDiscountPlanOption) ?? [],
      "discount_plan_name"
    );
    totalRes.cart = res.cart ? convertApiCartToCart(res.cart) : undefined;
    totalRes.discountPlanOptions = discountPlanOptions;
  } catch (err) {
    // 404が返った場合はリクエストパラメータからクーポンを除去して、もう一回POSTリクエストを投げる(※Nuxtの仕様をそのまま踏襲)
    // 1度目でエラー、2度目で成功の場合、ここでエラーを表示する必要があるため、再起呼び出しは行えない。
    const { coupon, ...paramsWithoutCoupon } = params;
    if (isAxiosError(err) && err.response?.status === 404) {
      const res = await postCart(convertObjToSnakeCase(paramsWithoutCoupon));
      const discountPlanOptions = mapBy(
        res.discount_plan_options?.map(convertApiDiscountPlanOptionToDiscountPlanOption) ?? [],
        "discount_plan_name"
      );
      totalRes.cart = res.cart ? convertApiCartToCart(res.cart) : undefined;
      totalRes.discountPlanOptions = discountPlanOptions;
    }
    htmlToast.error(getErrorMessages(err));
  }
  return totalRes;
}
