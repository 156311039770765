"use client";
import { ChangeEvent, useId } from "react";

import clsx from "clsx";

import { Row } from "@/components/containers";

import styles from "./PanelSelectorItem.module.scss";

export interface PanelSelectorItemProps {
  id?: string;
  title: React.ReactNode;
  content?: React.ReactNode;
  isSelected: boolean;
  onSelect: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  labelTwoRows?: boolean;
  "data-testid"?: string;
}

export function PanelSelectorItem({
  id,
  title,
  content,
  isSelected,
  onSelect,
  disabled,
  labelTwoRows,
  "data-testid": testId,
}: PanelSelectorItemProps): React.ReactNode {
  const generatedId = useId();
  const inputId = id ?? generatedId;
  return (
    <div
      className={clsx(styles.option, isSelected && styles.selected, disabled && styles.disabled)}
      data-testid={testId}
    >
      <Row className={clsx(styles.item, labelTwoRows && styles.labelTwoRows)}>
        <input
          id={inputId}
          checked={isSelected}
          type="radio"
          className={clsx(styles.radioInput, "expendable")}
          onChange={onSelect}
          disabled={disabled}
        />
        <label className="text__m" htmlFor={inputId}>
          {title}
        </label>
      </Row>
      {isSelected && content && (
        <div className={clsx(styles.toggleCell, "off__bottom")}>{content}</div>
      )}
    </div>
  );
}
