/**
 * Generated by orval v6.24.0 🍺
 * Do not edit manually.
 * Payse API
 * How to use payse API
 * OpenAPI spec version: 3.0.1
 */
import { useMutation, useQuery, useSuspenseQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from "@tanstack/react-query";
import type { CreateSetupIntent200, CreateSetupIntent400, GetSetupIntent200 } from ".././schemas";
import { customAxiosInstance } from "../../../configs/customAxiosInstance";
import type { ErrorType } from "../../../configs/customAxiosInstance";
import { customFormUrlEncodedFn } from "../../../configs/formUrlEncoded";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * SetupIntentの生成 + クライアントシークレットの取得
取得後の流れは以下:
1.クライアントシークレットをフロントエンドでconfirmCardPayment()に渡し、3DS認証を行う
2.confirmCardPayment()のレスポンスからpayment_method_idを抽出する
3.$payment_data['stripe_payment_method_id']に入れて注文APIへ送信する
 * @summary stripe setup-intent create
 */
export const createSetupIntent = (options?: SecondParameter<typeof customAxiosInstance>) => {
  return customAxiosInstance<CreateSetupIntent200>(
    { url: `/stripe/setup-intent`, method: "POST" },
    options
  );
};

export const getCreateSetupIntentMutationOptions = <
  TError = ErrorType<CreateSetupIntent400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSetupIntent>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof createSetupIntent>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSetupIntent>>, void> = () => {
    return createSetupIntent(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateSetupIntentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSetupIntent>>
>;

export type CreateSetupIntentMutationError = ErrorType<CreateSetupIntent400>;

/**
 * @summary stripe setup-intent create
 */
export const useCreateSetupIntent = <
  TError = ErrorType<CreateSetupIntent400>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSetupIntent>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customAxiosInstance>;
}) => {
  const mutationOptions = getCreateSetupIntentMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * SetupIntentの取得 (※注文前に購入フォーム内でクレジットカードのlast4を使いたい時にのみ使用します)
 * @summary stripe setup-intent get
 */
export const getSetupIntent = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
  signal?: AbortSignal
) => {
  return customAxiosInstance<GetSetupIntent200>(
    { url: `/stripe/setup-intent/${id}`, method: "GET", signal },
    options
  );
};

export const getGetSetupIntentQueryKey = (id: string) => {
  return [`/stripe/setup-intent/${id}`] as const;
};

export const getGetSetupIntentQueryOptions = <
  TData = Awaited<ReturnType<typeof getSetupIntent>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSetupIntentQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSetupIntent>>> = ({ signal }) =>
    getSetupIntent(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, staleTime: 30000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSetupIntent>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSetupIntentQueryResult = NonNullable<Awaited<ReturnType<typeof getSetupIntent>>>;
export type GetSetupIntentQueryError = ErrorType<unknown>;

/**
 * @summary stripe setup-intent get
 */
export const useGetSetupIntent = <
  TData = Awaited<ReturnType<typeof getSetupIntent>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSetupIntentQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary stripe setup-intent get
 */
export const prefetchGetSetupIntent = async <
  TData = Awaited<ReturnType<typeof getSetupIntent>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData>>;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetSetupIntentQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getGetSetupIntentSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getSetupIntent>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSetupIntentQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSetupIntent>>> = ({ signal }) =>
    getSetupIntent(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    staleTime: 30000,
    ...queryOptions,
  } as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type GetSetupIntentSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSetupIntent>>
>;
export type GetSetupIntentSuspenseQueryError = ErrorType<unknown>;

/**
 * @summary stripe setup-intent get
 */
export const useGetSetupIntentSuspense = <
  TData = Awaited<ReturnType<typeof getSetupIntent>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): UseSuspenseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSetupIntentSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary stripe setup-intent get
 */
export const prefetchGetSetupIntentSuspense = async <
  TData = Awaited<ReturnType<typeof getSetupIntent>>,
  TError = ErrorType<unknown>,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSetupIntent>>, TError, TData>
    >;
    request?: SecondParameter<typeof customAxiosInstance>;
  }
): Promise<QueryClient> => {
  const queryOptions = getGetSetupIntentSuspenseQueryOptions(id, options);

  await queryClient.prefetchSuspenseQuery(queryOptions);

  return queryClient;
};
