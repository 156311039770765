"use client";
import { ChangeEvent, FocusEventHandler, useCallback, useMemo, useState } from "react";

import clsx from "clsx";

import { Merge } from "@/utils";

import styles from "./Selector.module.scss";

type BaseProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

export type SelectorOption<T> = {
  value: T;
  label: string;
};

type OwnProps<T> = {
  options: ReadonlyArray<SelectorOption<T>>;
  onChange?: (value: T, e: ChangeEvent<HTMLSelectElement>) => void;
  value?: T;
  defaultValue?: T;
  innerRef?: React.RefObject<HTMLSelectElement> | React.LegacyRef<HTMLSelectElement>;
  "data-testid"?: string;
};

type SelectorProps<T> = Merge<BaseProps, OwnProps<T>>;

/**
 * 標準の`select`要素を拡張し、文字列だけでなく任意の型の`value`を扱えるようにしたコンポーネントです。
 * また`option`要素を内包することで、使用時の冗長性を排除しています。
 */
export function Selector<T>({
  options,
  onChange,
  onBlur,
  onFocus,
  value: propsValue,
  defaultValue,
  className,
  disabled,
  innerRef,
  ...rest
}: SelectorProps<T>): React.ReactNode {
  const [innerValue, setInnerValue] = useState(defaultValue);
  const value = propsValue ?? innerValue;

  const [optionsWithSelectorValue, valueOptionMap] = useMemo(() => {
    const optionMap = new Map<string, SelectorOption<T>>();
    const newOptions = options.map((option) => {
      const selectorValue = JSON.stringify(option.value);
      optionMap.set(selectorValue, option);
      return {
        selectorValue,
        ...option,
      };
    });

    return [newOptions, optionMap] as const;
  }, [options]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const selectorValue = e.target.value;
      const selectedOption = valueOptionMap.get(selectorValue);
      if (selectedOption) {
        onChange?.(selectedOption.value, e);
        setInnerValue(selectedOption.value);
      }
    },
    [onChange, valueOptionMap]
  );

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback<FocusEventHandler<HTMLSelectElement>>(
    (e) => {
      onFocus?.(e);
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleBlur = useCallback<FocusEventHandler<HTMLSelectElement>>(
    (e) => {
      onBlur?.(e);
      setIsFocused(false);
    },
    [onBlur]
  );

  const selectorValue = useMemo(() => {
    return optionsWithSelectorValue.find((option) => option.value === value)?.selectorValue ?? "";
  }, [optionsWithSelectorValue, value]);

  return (
    <div className={clsx(styles.root, isFocused && styles.focused, className)}>
      <select
        value={selectorValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={styles.select}
        disabled={disabled}
        ref={innerRef}
        {...rest}
      >
        {optionsWithSelectorValue.map((option) => (
          <option key={option.selectorValue} value={option.selectorValue}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
