import { useEffect } from "react";

import { htmlToast } from "@/components/feedbacks/htmlToast";
import { CartModel } from "@/models/cart/type";
import { useClientFrontStoreCartItems } from "@/storage";

/**
 * カートから終売商品を削除する
 */
export function useRemoveSuspendedProductsFromCart(cart: CartModel | undefined) {
  const { cartItems, setCartItems } = useClientFrontStoreCartItems();

  useEffect(() => {
    if (!cart) {
      return;
    }

    // 終売商品の取得
    const suspendedProducts = cart.products
      .filter((product) => product.isSuspension)
      .map((product) => product.variantId);
    if (suspendedProducts.length === 0) {
      return;
    }

    // 終売商品をカートから削除
    const cartItemsWithoutSuspended = cartItems.filter(
      (item) => !suspendedProducts.includes(item.variantId)
    );

    /**
     * - setCartItems を実行すると localStorage が更新される
     *   その後カートの再取得がトリガーされ、新しいカート情報が戻るまで時間がかかる
     * - 新しいカート情報が戻るまで、終売商品が一時的にカートに残る
     *
     * そのため、localStorage への反映は商品数が変更された場合のみ行う
     */
    if (cartItemsWithoutSuspended.length !== cartItems.length) {
      setCartItems(cartItemsWithoutSuspended);
      htmlToast.error("販売期間を過ぎた商品があるので削除されました。");
    }
  }, [cart, cartItems, setCartItems]);
}
